<template>
  <RekapPasokanMitraForm mode="Ubah" module="Rekap Pasokan Mitra"> </RekapPasokanMitraForm>
</template>

<script>
import RekapPasokanMitraForm from './form';

const RekapPasokanMitraUpdate = {
  name: 'RekapPasokanMitraUpdate',
  components: { RekapPasokanMitraForm },
};

export default RekapPasokanMitraUpdate;
</script>
